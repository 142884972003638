<template>
  <div class="pt-36 px-5 md:px-24">
      <div class="grid grid-flow-row md:grid-cols-3 md:grid-rows-6 gap-16">
        <div class="md:col-start-1 md:row-start-1">
          <label
            for="name"
            class="block mb-2 text-sm font-semibold StemTextSecond"
            >Emri dhe mbiemri *</label
          >
          <input
            type="text"
            name="name"
            placeholder="Emri dhe mbiemri"
            v-model="name"
            required
            class="
              w-full
              px-3
              py-2
              placeholder-gray-300
              border-2 border-StemYellow
              focus:outline-none
              focus:ring
              focus:ring-StemYellow
              focus:border-StemYellow
            "
          />
        </div>
        <div class="md:col-start-2 md:row-start-1">
          <label
            for="email"
            class="block mb-2 text-sm font-semibold StemTextSecond"
            >E-mail adresa *</label
          >
          <input
            type="email"
            name="email"
            placeholder="you@email.com"
            v-model="email"
            required
            class="
              w-full
              px-3
              py-2
              placeholder-gray-300
              border-2 border-StemYellow
              focus:outline-none
              focus:ring
              focus:ring-StemYellow
              focus:border-StemYellow
            "
          />
        </div>
        <div class="md:col-start-1 md:row-start-2">
          <label
            for="institution"
            class="block mb-2 text-sm font-semibold StemTextSecond"
            >Institucioni</label
          >
          <input
            type="text"
            name="institution"
            placeholder="Institution"
            v-model="institution"
            class="
              w-full
              px-3
              py-2
              placeholder-gray-300
              border-2 border-StemYellow
              focus:outline-none
              focus:ring
              focus:ring-StemYellow
              focus:border-StemYellow
            "
          />
        </div>
        <div class="md:col-start-2 md:row-start-2">
          <label
            for="phone"
            class="block mb-2 text-sm font-semibold StemTextSecond"
            >Numri i kontaktit *</label
          >
          <input
            type="tel"
            pattern="[0-9]{3}-[0-9]{3}-[0-9]{3}"
            name="phone"
            placeholder="070123123"
            v-model="phone"
            required
            class="
              w-full
              px-3
              py-2
              placeholder-gray-300
              border-2 border-StemYellow
              focus:outline-none
              focus:ring
              focus:ring-StemYellow
              focus:border-StemYellow
            "
          />
        </div>
        <div class="md:col-start-1 md:col-span-2 md:row-start-3 md:row-span-2">
          <label
            for="msg"
            class="block mb-2 text-sm font-semibold StemTextSecond"
            >Porosia *</label
          >
          <textarea
            type="text"
            name="msg"
            placeholder="Dear StemLab..."
            v-model="msg"
            required
            class="
              w-full
              h-full
              px-3
              py-2
              placeholder-gray-300
              border-2 border-StemYellow
              focus:outline-none
              focus:ring
              focus:ring-StemYellow
              focus:border-StemYellow
            "
          />
        </div>
        <div class="md:col-start-1 md:col-span-2 md:row-start-5">
          <div class="flex justify-center gap-5">
          <div class="pt-3">
          <input type="checkbox" id="checkbox" v-model="checked" required />
          <label for="checkbox" class="pl-2">Konfirmo</label>
          </div>
          <button
                :disabled="!checked"
                @click="sendEmail"
                :class="{'bg-StemYellow text-white hover:text-StemYellow hover:border-StemYellow hover:bg-transparent' : checked, 
                'bg-yellow-100 text-white ': !checked}"
                class="
                  rounded-lg
                  
                  border
                  text-base
                  font-normal
                  shadow-2xl
                  px-6
                  py-3
                "
              >
                Dergo
              </button>
        </div>
        </div>
          

        <div class="md:col-start-3 md:row-start-1 md:row-span-5 row-start-1">
          <div class="grid grid-flow-row">
            <div class="border-StemPurple border-l-2 pl-4">
              <h1 class="text-StemBlue font-bold text-4xl pr-4 text-left">
                Na kontaktoni
              </h1>
            </div>
            <div>
              <p
                class="
                  text-left
                  pt-10
                  text-StemTextSecond
                  font-normal
                  text-lg
                  leading-loose
                  px-2
                "
              >
                Ju mund të na kontaktoni për të vizituar hapsirat e STEMLab,
                konsultoheni për programe të veçanta, caktuar termine për
                demo-orë apo për të marrë informacione shtesë në:
              </p>
            </div>
            <div class="grid grid-cols-3 grid-rows-3">
              <h1
                class="
                  col-start-1
                  text-left
                  pt-10
                  text-StemTextSecond
                  font-bold
                  text-lg
                  leading-loose
                  px-2
                "
              >
                Adresa:
              </h1>
              <p
                class="
                  row-start-1
                  col-start-2 col-span-2
                  text-left
                  pt-10
                  text-StemTextSecond
                  font-normal
                  text-lg
                  leading-loose
                  px-2
                "
              >
                Ilindenska 177, Tetovë 1220
              </p>
              <h1
                class="
                  row-start-2
                  col-start-1
                  text-left
                  pt-10
                  text-StemTextSecond
                  font-bold
                  text-lg
                  leading-loose
                  px-2
                "
              >
                Email:
              </h1>
              <p
                class="
                  row-start-2
                  col-start-2 col-span-2
                  text-left
                  pt-10
                  text-StemTextSecond
                  font-normal
                  text-lg
                  leading-loose
                  px-2
                "
              >
                info@stemlab.org.mk
              </p>
              <h1
                class="
                  row-start-3
                  col-start-1
                  text-left
                  pt-10
                  text-StemTextSecond
                  font-bold
                  text-lg
                  leading-loose
                  px-2
                "
              >
                Mob:
              </h1>
              <p
                class="
                  row-start-3
                  col-start-2 col-span-2
                  text-left
                  pt-10
                  text-StemTextSecond
                  font-normal
                  text-lg
                  leading-loose
                  px-2
                "
              >
                +389 (0)70 840 640
              </p>
            </div>
          </div>
        </div>
      </div>
  </div>
</template>


  <script>
import { sendEmailService } from "../../services/email";
import { CONTACT } from "../../constants/constants";
export default {
  data() {
    return {
      name: "",
      email: "",
      phone: "",
      institution: "",
      msg: "",
      checked: false,
    };
  },
  methods: {
    async sendEmail() {
      if (!this.checked) {
        // TODO: fix UI to display checked as requiered
        return;
      }

      const data = {
        name: this.name,
        email: this.email,
        phone: this.phone,
        institution: this.institution,
        msg: this.msg,
      };

      const res = await sendEmailService(CONTACT, data);
      if (!res) {
        console.log("Mail not sent");
      }

      // Reset form field
      this.name = "";
      this.email = "";
      this.phone = "";
      this.institution = "";
      this.msg = "";
    },
  },
};
</script>

<style>
</style>