<template>
  <div class="bg-white">
  <NavBarNew></NavBarNew>
  <LocationMap></LocationMap>
  <ContactUsForm></ContactUsForm>
  <Footer></Footer>
  </div>
</template>

<script>
// @ is an alias to /src
import NavBarNew from "@/components/HomeScreen/NavBarNew.vue";
import LocationMap from "@/components/ContactUs/LocationMap.vue"
import ContactUsForm from "@/components/ContactUs/ContactUsForm.vue"

import Footer from "@/components/HomeScreen/Footer.vue";

export default {
  name: "ContactUs",
  components: {
    NavBarNew,
    LocationMap,
    ContactUsForm,
    Footer,
  },
};
</script>