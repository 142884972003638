<template>
  <div class="pt-32 px-5 md:px-24">
    <div>
      <div class="mapouter">
        <div class="gmap_canvas">
          <iframe
            class="w-full h-80"
            id="gmap_canvas"
            src="https://maps.google.com/maps?q=stemlab%20tetovo&t=&z=17&ie=UTF8&iwloc=&output=embed"
            frameborder="0"
            scrolling="no"
            marginheight="0"
            marginwidth="0"
          ></iframe
          ><a
            href="https://www.embedgooglemap.net/blog/divi-discount-code-elegant-themes-coupon/"
          ></a
          >
        </div>
      </div>
    </div>
  </div>
  <!-- <div class="mapouter"><div class="gmap_canvas"><iframe width="600" height="500" id="gmap_canvas" src="https://maps.google.com/maps?q=stemlab%20tetovo&t=&z=17&ie=UTF8&iwloc=&output=embed" frameborder="0" scrolling="no" marginheight="0" marginwidth="0"></iframe><a href="https://123movies-to.org"></a><br><style>.mapouter{position:relative;text-align:right;height:500px;width:600px;}</style><a href="https://www.embedgooglemap.net">embedgooglemap.net</a><style>.gmap_canvas {overflow:hidden;background:none!important;height:500px;width:600px;}</style></div></div> -->
</template>


<script>
export default {};
</script>

<style scoped>
.mapouter {
              position: relative;
              text-align: right;
              /* height: 333px;
              width: 1206px; */
            }
</style>